//
// general.scss
//

html {
    position: relative;
    min-height: 100%;
}

body {
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
    body {
        cursor: pointer;
    }
}

.btn-close {
    box-sizing: content-box !important;
    width: 1em !important;
    height: 1em !important;
    padding: 0.25em 0.25em !important;
    color: #000 !important;
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
        center / 1em auto no-repeat !important;
    border: 0 !important;
    border-radius: 0.25rem !important;
    opacity: 0.5 !important;
}

.carousel-indicators {
    bottom: -50px;
}

.carousel-indicators button {
    margin: .25rem;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    opacity: 0.5;
}

.carousel-indicators button.active {
    background-color: #0136e5
}


.bg-black {
    background-color: #141414;
}

.font-edge-blue {
    color: #0136e5;
}

.center-wizard-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: #2ca9f2;
    white-space: pre;
}

.center-title {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: #2ca9f2;
    white-space: pre;
}

.center-title-balance {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: #f8c761;
    white-space: pre;
}

.center-title-progress {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: white;
    white-space: pre;
    width: 100%;
    max-width: 100%;
    text-align: center;
}

.progress {
    width: 40% !important;
    height: 0.75rem;
    margin-top: 0.3rem;
    margin-left: auto;
    margin-right: auto;
}

#payment-form > .StripeElement {
    height: auto;
}
